.productContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(245, 229, 0); */
    width: 30%;
    margin-bottom: 20px;

}
.productContainer:hover{
    cursor: pointer;
    animation-name: scaleUp;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
.productImage{
    width: 100%;
    height: 100%;
    position: relative;
}

.productImage:hover{
    animation-name: bordering;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

}
.productPrice{
    position: relative;
    color: white;
    bottom: 15%;
    color: white;
    font-weight: 500;
    font-size: xx-large;
    filter: drop-shadow(2px 2px 2px black);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: 0px;

}
.productNoPrice{

    background-color: green;
    position: relative;
    color: white;
    bottom: 15%;
    color: white;
    font-weight: 500;
    font-size: xx-small;
    filter: drop-shadow(2px 2px 2px black);
    padding: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: 100%;
}
.productName{
    padding: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    top: 10%;
    z-index: 3;
    color: white;
    font-weight: 500;
    font-size: xx-large;
    filter: drop-shadow(2px 2px 2px black);
    height: 0px;
}
@media (max-width: 720px) {
    .productName{
        font-size: small;
    }
    .productPrice{
        font-size: small;
        
    }
}
@keyframes scaleUp {
    100%{
        transform: scale(1.1, 1.1);

    }
    
}
@keyframes bordering {
    100%{
        border-radius: 20px;

    }
    
}