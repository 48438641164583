.registerContainer{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    background-color: darkblue;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.inputGroup{
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 50vh;
}
.inputGroup input{
    padding: 20px;
    font-size: medium;
}
.formContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.registerButton{
    padding: 20px;
    border-radius: 10px;
    width: 50vh;
    cursor: pointer;
    font-weight: 600;
}
.registerButton:hover{
    background-color: yellow;
}
.usersCardPlaceHolderContainer{
    width: 50%;
    color: black;
    
}
.validatingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;
}
@media (max-width: 600px ) {
    .registerContainer{
        flex-direction: row;
        flex-wrap: wrap;
    }
}