.landingContainer{
    position: relative;
    z-index: -3;
}
.plansContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: -3;
}
.gradesContainer{
    
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
    position: relative;
    z-index: -3;
    
}
.featuresContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: -3;
}
.extrasContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin-top: 30px;
    z-index: 2;
    margin-bottom: 20PX;
}
.firstSectionContainer{
    background-color: whitesmoke;
    position: relative;
    padding: 10px;
    z-index: -4;
}