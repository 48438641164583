.footerContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    background-color: black;
    align-items: flex-start;
    color: white;
    bottom: 0;
}
.credits{
    position: relative;
    /* bottom: 20px; */
    color: white;
    padding-bottom: 5px;

    
}
.socialMediaContainer{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    cursor: pointer;
    padding: 10px;
    background-color: white;
}
.socialMediasContainer{
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */

}
.socialMediaImage{
    width: 20px;

}
@media (max-width: 800px) {
    .footerContainer{
        position: relative;
    }
}