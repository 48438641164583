.userDetailImage{
    border-radius:  700px;
    width: 50%;
}
.dataContainer{
    display: flex;
    flex-direction: column;
}
.gradesContainerUserPage{
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50%;
    border: 3px solid black;
    margin-block: 15px;
    /* background-color: antiquewhite; */
}
.userNamePage{
    letter-spacing: 3px;
    filter: drop-shadow(2px 3px 0.5px white);

}
.userDetailContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.imageUserPageContainer{
    /* background-color: red; */
    padding: 20px;
    margin-bottom: 15px;
}
.logoutButton{
    margin-top: 30px;
    border-radius: 50px;
    background-color: rgb(216, 128, 128);
    padding: 10px;
    color: white;
    animation-name: unspacingLetters;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}
.logoutButton:hover{
    background-color: red;
    cursor: pointer;
    animation-name: spacingLetters;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    
}
.logoutText{
    filter: drop-shadow(2px 2px 1px black);
}
@keyframes spacingLetters {
    100%{
        letter-spacing: 3px;
    }
    
}
@keyframes unspacingLetters {
    0%{
        letter-spacing: 3px;
    }
    100%{
        letter-spacing: 1px;
    }
    
}