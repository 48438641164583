.planContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(219, 219, 51);
    padding: 20px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 250px;
    margin: 5px;
    filter: drop-shadow(5px 5px  gold);
    z-index: -3;

}
.planImage{
    background-color: rgb(205, 181, 47);
    padding: 10px;
    border-radius: 20px;
    /* border: 2px double black ; */
    /* filter: drop-shadow(3px 3px orange); */
}
.planTitle{
    font-size: xx-large;
    color: white;
    margin-bottom: 15px;
    background-color: rgb(11, 11, 111);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.planDivider{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin-top: 20px;
    margin-bottom: 20px;
}
.planDescription{
    padding: 10px;
    line-height: 25px ;

}
.planButton{
    padding: 10px;
    background-color: gold;
    cursor: pointer;
    margin-top: 10px;
}
.planButton:hover{
    background-color: rgb(11, 11, 111) ;
    color: white;
}
.planInfo{
    padding: 10px;
    line-height: 25px ;
}
.planImageItem{
    width: 100%;
}